.profile-card{
    background-image: url(../../assets/images/profile_card_bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    font-family: retropix;
}

@font-face {
    font-family: retropix;
    src: url(./../../assets/retropix.ttf);
}
