.moon-img {
  height: 18vw;
  width: 18vw;
  position: absolute;

  opacity: 70%;
  animation: moonRotate 10s linear 0s infinite;
}

@keyframes moonRotate {
  0% {
    transform: translateX(200%) translateY(-55%) rotate(0deg);
  }

  50% {
    transform: translateX(200%) translateY(-55%) rotate(180deg);
  }

  100% {
    transform: translateX(200%) translateY(-55%) rotate(360deg);
  }
}

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
  .about-body-container {
    max-width: 90vw;
  }
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
  .about-body-container {
    max-width: 90vw;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .about-body-container {
    max-width: 90vw;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .about-body-container {
    max-width: 85vw;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .about-body-container {
    max-width: 45vw;
  }
}


.about-top-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}