.shooting-star {
    position: absolute;
    top: -100px;
    left: -100px;
    display: flex;
    align-items: center;
    opacity: 0%;
}

.shooting-star-tail {
    height: 2px;
    width: 60px;
    background: linear-gradient(90deg, #ffffff, rgba(145, 152, 229, 0));
}

.star-body {
    width: 5px;
    height: 5px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 5px rgb(163, 164, 164);
    z-index: -1;
}

@keyframes shootingStar {
    0% {
        transform: translateY(0vw) translateX(0vw) rotate(-135deg);
        opacity: 0%;
    }

    50% {
        opacity: 100%;
    }

    100% {
        transform: translateY(50vw) translateX(50vw) rotate(-135deg);
        opacity: 0%;
    }
}