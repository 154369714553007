.mainpage {
  height: max-content;
  padding-top: 20px;
}

/* ASTRONAUT1 ANIMATION */
.astronaut1 {
  width: 100px;
  height: 100px;
  position: fixed;
  right: 0;;
  top: 0;
  /* z-index: -1; */

  animation-name: astronautFloat1;
  animation-iteration-count: infinite;
  animation-duration: 20s;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-timing-function: linear;

  opacity: 0%;
}

@keyframes astronautFloat1 {
  0% {
    transform: translateY(-20vh) rotate(0deg);
    opacity: 100%;
  }

  35% {
    transform: translateY(120vh) rotate(180deg);
    opacity: 100%;
  }

  36% {
    transform: translateY(120vh) rotate(180deg);
    opacity: 0%;
  }

  100% {
    transform: translateY(120vh) rotate(180deg);
    opacity: 0%;
  }
}

/* ASTRONAUT2 ANIMATION */
.astronaut2 {
  width: 100px;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  /* z-index: -1; */

  animation-name: astronautFloat2;
  animation-iteration-count: infinite;
  animation-duration: 20s;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-timing-function: linear;

  opacity: 0%;
}

@keyframes astronautFloat2 {
  0% {
    transform: translateY(-20vh) rotate(0deg);
    opacity: 100%;
  }

  50% {
    transform: translateY(-20vh) rotate(0deg);
    opacity: 100%;
  }

  85% {
    transform: translateY(120vh) rotate(180deg);
    opacity: 100%;
  }

  86% {
    transform: translateY(120vh) rotate(180deg);
    opacity: 0%;
  }

  100% {
    transform: translateY(120vh) rotate(180deg);
    opacity: 0%;
  }
}


/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
  .astronaut1 .astronaut2 {
    width: 15vw;
    height: 15vw;
  }
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
  .astronaut1 .astronaut2 {
    width: 15vw;
    height: 15vw;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .astronaut1 .astronaut2 {
    width: 10vw;
    height: 10vw;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .astronaut1 .astronaut2 {
    width: 100px;
    height: 100px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .astronaut1 .astronaut2 {
    width: 100px;
    height: 100px;
  }
}