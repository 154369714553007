.rules-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  padding-left: 4em;
  padding-right: 4em;
}

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
  .rules-container {
    padding-left: 1em;
    padding-right: 1em;
  }
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
  .rules-container {
    padding-left: 1em;
    padding-right: 1em;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .rules-container {
    padding-left: 2em;
    padding-right: 2em;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .rules-container {
    padding-left: 3em;
    padding-right: 3em;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .rules-container {
    padding-left: 4em;
    padding-right: 4em;
  }
}
