.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.logo-image {
    padding: 10px;
}

.notify-button {
    font-family: 'ABeeZee', sans-serif;
    font-size: 1.5rem;

}

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
    .logo-image {
        padding: 10px;
        max-width: 200px;
    }    
}

.header-text-section {
    max-width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
    .logo-image {
        padding: 10px;
        max-width: 300px;
    }
    .header-text-section {
        max-width: 80vw;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .logo-image {
        padding: 10px;
        max-width: 300px;
    }
    .header-text-section {
        max-width: 80vw;
    }
    .text-section {
        max-width: 60vw;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .logo-image {
        padding: 10px;
        max-width: 400px;
    }
    .header-text-section {
        max-width: 80vw;
    }
    .text-section {
        max-width: 40vw;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .logo-image {
        padding: 10px;
        max-width: 500px;
    }
    .header-text-section {
        max-width: 40vw;
    }
    .text-section {
        max-width: 30vw;
    }
}

.button-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}