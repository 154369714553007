.dashboard {
    height: max-content;
    min-height: 100vh;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.event-password {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 16px;
}