.pastEventPic {
    /* padding: 80px; */
    max-width: 20vw;
    z-index: 1;
}

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
    .pastEventPic {
        max-width: 80vw;
    }
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
    .pastEventPic {
      max-width: 40vw;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .pastEventPic {
      max-width: 40vw;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .pastEventPic {
      max-width: 20vw;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .pastEventPic {
      max-width: 20vw;
    }
  }