.schedule-container {
    padding-top: 20px;
    height: max-content;
    min-height: 100vh;
}

.event-container {
    padding: 20px;
    max-width: 900px;
    border-radius: 10px;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    flex-basis: 100%;
    margin: 20px 20px 20px 20px;
}