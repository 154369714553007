.judging-criteria-section {
    max-width: 80vw;
}

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
    .judging-criteria-section {
        max-width: 100vw;
    }
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
    .judging-criteria-section {
        max-width: 100vw;
    }
    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .judging-criteria-section {
        max-width: 100vw;
    }
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .judging-criteria-section {
        max-width: 90vw;
    }
    
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .judging-criteria-section {
        max-width: 80vw;
    }
    
}