/* Leaflet section */
.venue-map-container {

  /* center the map */
  display: flex;
  justify-content: center;
  align-items: center;
  

}

.leaflet-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 75vw; 
  height: 65vh;
}

@media (max-width: 768px) {
  .leaflet-container {
    height: 50vh;
  }
}

@media (max-width: 576px) {
  .leaflet-container {
    height: 40vh;
  }
}

.venue-popup {
  text-align: center;
}