.rocket-container {
    position: absolute;

    width: 80%;

    top: -100px;
    left: -100px;
    display: flex;
    align-items: center;
}

.rocket-img {
    width: 100px;
    transform: rotate(135deg);
    opacity: 70%;
}

@keyframes rocketFlight {
    0% {
        transform: rotate(225deg);
    }

    100% {
        transform: rotate(-135deg);
    }
}