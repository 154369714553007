.room {
  max-width: 50vw;
  margin: 5px;
  animation: roomHover 3s ease-in-out 0s infinite;
}

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
  .room {
    max-width: 80vw;
  }
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
  .room {
    max-width: 70vw;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .room {
    max-width: 60vw;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .room {
    max-width: 50vw;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .room {
    max-width: 50vw;
  }
}

@keyframes roomHover {
  0% {
    transform: translateY(-1vw);
  }

  50% {
    transform: translateY(2vw);
  }

  100% {
    transform: translateY(-1vw);
  }
}

.contact-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.justify-content-center {
  justify-content: center !important;
}

/* Make the icons larger */
.icons-section {
  font-size: 2.5rem;
}