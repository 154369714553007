#registerForm {
    background-color: #264258;
    /* width: 40%; */
    max-width: 700px;
    margin: 5em auto;
    padding: 4em 4em 10em 4em;
    position: relative;
    border-radius: 2em;
    overflow: hidden;
    position: relative;
}

#registerForm .subactions a:hover {
    color: #fff;
}

#registerForm .alienPair {
    display: flex;
    position: absolute;
    width: 100%;
    justify-content: space-between;
    bottom: -150px;
    left: 50%;
    transform: translateX(-50%);
}

#registerForm .alienPair img {
    width: 300px;
}

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
    #registerForm {
        padding: 4em 1em 10em 1em;
        margin: 0 auto;
      }
    
    #registerForm .alienPair {
        justify-content: center;
    }
    
    #registerForm .alienPair img {
        margin-left: -40px;
        margin-right: -40px;
    }
}  

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
    #registerForm {
      padding: 4em 3em 10em 3em;
      margin: 2em auto;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    #registerForm {
        padding: 4em 4em 10em 4em;
        margin: 5em auto;
      }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    #registerForm {
        padding: 4em 4em 10em 4em;
        margin: 5em auto;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    #registerForm {
        padding: 4em 4em 10em 4em;
        margin: 5em auto;
    }
  }
