.login {
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;    
    align-items: flex-start;
}

.login button {
    margin-top: 0.5em;
}

#loginFormBg {
    height: 100%;
    padding: 2em;
}

#loginForm {
    background-color: #264258;
    /* width: 40%; */
    max-width: 800px;
    margin: 5em auto;
    padding: 2em 4em 4em 4em;
    position: relative;
    border-radius: 2em;
}

.login input[type="text"],
.login input[type="email"],
.login input[type="password"] {
    background-color: #F9FAF2;
    color: #000000;
}

.login input {
    outline: none;
    border: none;
    font-size: 1.2em;
    text-align: center;
    padding: .5em;
}

.login .last {
    margin-bottom: 0;
}

.login button {
    font-size: 1.2em;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    line-height: 1;
    padding: 15px;
    margin-top: 20px;
    border-radius: 10px;
}

#loginForm .checkbox_group,
#registerForm .checkbox_group {
    text-align: left;
    padding-left: 2em;
    text-indent: -2.5em;
    margin-bottom: 1em;
}

#loginForm::before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    right: auto;
    top: auto;
    bottom: -110px;
    border: 60px solid;
    border-color: #264258 transparent transparent transparent;
}

.alien2 {
    position: relative;
    left: auto;
    right: auto;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    top: -50px;
    height: auto;
}

#loginForm .subactions {
    margin-top: .5em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#loginForm .subactions a:hover {
    color: #fff;
}

.auth_success {
    color: rgb(27, 204, 204);
}

.auth_failure,
.highlight_btn {
    color: #ffad00;
}

.highlight_btn {
    text-decoration: underline;
}

.auth_feedback {
    display: none;
}

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
    #loginForm {
        padding: 1.5em 1em 4em 1em;
        margin: 0 auto;
    }
    .alien2 {
        top: 10px;
    }
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
    #loginForm {
        padding: 1.5em 2em 4em 2em;
        margin: 2em auto;
    }
    .alien2 {
        top: -10px;
    }
}

@media only screen and (min-width: 992px) {
    #loginForm {
        padding: 2em 4em 4em 4em;
        margin: 5em auto;
    }
    .alien2 {
        top: -50px;
    }
}
