.jamImg {
  /* padding: 80px; */
  max-width: 20vw;
  z-index: 1;
}

.alien-img {
  width: 10vw;
  transition: 0.3s;
  position: relative;
  z-index: 4;
  margin-bottom: calc(-10vw);
}

.images-container {
  overflow: hidden;
  cursor: pointer;
}

.images-container:hover>.alien-img {
  transform: translateY(-5vw);
}

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
  .jamImg {
    max-width: 80vw;
  }
  .alien-img {
    width: 40vw;
    transition: 0.3s;
    position: relative;
    z-index: 4;
    margin-bottom: calc(-40vw);
  }
  .images-container:hover>.alien-img {
    transform: translateY(-20vw);
  }
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
  .jamImg {
    max-width: 40vw;
  }
  .alien-img {
    width: 17vw;
    transition: 0.3s;
    position: relative;
    z-index: 4;
    margin-bottom: calc(-17vw);
  }
  .images-container:hover>.alien-img {
    transform: translateY(-10vw);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .jamImg {
    max-width: 40vw;
  }
  .alien-img {
    width: 10vw;
    transition: 0.3s;
    position: relative;
    z-index: 4;
    margin-bottom: calc(-10vw);
  }
  .images-container:hover>.alien-img {
    transform: translateY(-5vw);
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .jamImg {
    max-width: 20vw;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .jamImg {
    max-width: 20vw;
  }
}