.submission {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;
}

.header-sub-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.header-sub {
    text-decoration-color: #ffad00;
    text-underline-offset: 15px;
}

.ufo-submission {
    padding: 10px;
    max-width: 100px;
    transform: scaleX(-1);
}

.alien {
    width: 200px;
    margin-left: -35px;
    margin-right: -30px;
    margin-bottom: -120px;
    z-index: 1;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .alien {
        width: 150px;
        margin-left: -35px;
        margin-right: -30px;
        margin-bottom: -120px;
        z-index: 1;
    }
}