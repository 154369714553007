.planet-img {
  position: absolute;
  /* background-color: crimson; */
  z-index: 3;

  /* padding: 40px; */

  opacity: 70%;
  animation: planetRotate 10s linear 0s infinite;
}

#planet-img1 {
  left: 0;
  width: 10vw;
  height: 10vw;
}

#planet-img2 {
  left: 85vw;
  width: 7vw;
  height: 7vw;
}

@keyframes planetRotate {
  0% {
    transform: translateY(300%) rotate(0deg);
  }

  50% {
    transform: translateY(300%) rotate(180deg);
  }

  100% {
    transform: translateY(300%) rotate(360deg);
  }
}

.prizes-section {
  margin-top: -10%;
  /* background-color: aquamarine; */
}

.prizes-section-container {
  margin-top: -100px;
  overflow: hidden;
}
