.faq-section-container {
  display: flex;
  justify-content: center;
}

.faq-section {
  width: 80vw;
}

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
  .faq-section {
    width: 100vw;
  }
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
  .faq-section {
    width: 100vw;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .faq-section {
    width: 100vw;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .faq-section {
    width: 90vw;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .faq-section {
    width: 80vw;
  }
}

/* Background planets stuff */
.planet-img-faq {
  position: absolute;
  /* background-color: crimson; */
  z-index: 3;
  /* padding: 40px; */

  opacity: 70%;
  animation: planetRotateFAQ 10s linear 0s infinite;
}

#planet-img3 {
  left: 0;

  width: 7vw;
  height: 7vw;
}

#planet-img7 {
  left: 85vw;

  width: 10vw;
  height: 10vw;
}

@keyframes planetRotateFAQ {
  0% {
    transform: translateY(60%) rotate(0deg);
  }

  50% {
    transform: translateY(60%) rotate(180deg);
  }

  100% {
    transform: translateY(60%) rotate(360deg);
  }
}

/* .faq-section {
    margin-top: -10%;
  } */

/* .faq-section-container {
    margin-top: -100px;
    overflow: hidden;
  }
   */
